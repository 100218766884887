<template>
  <v-container fluid>
    <v-overlay
      v-if="$apollo.queries.project.loading"
      color="white"
      opacity="1"
      absolute
    >
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      />
    </v-overlay>
    <template v-else>
      <v-card
        class="ma-0"
        outlined
      >
        <project-view
          :event="event"
          :project="project"
        />
      </v-card>
    </template>
  </v-container>
</template>

<script>
import ProjectView from './components/core/ProjectView'
export default {
  name: 'DashboardProject',
  components: { ProjectView },
  props: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
    project (newValue) {
      console.log('PROJECT HAS CHANGED', newValue)
    }
  },
  created () {
  },
  methods: {
    refetch () {
      console.log(this.$options.name, 'Re-fetching')
      this.$apollo.queries.event.refetch()
      this.$apollo.queries.project.refetch()
    }
  },
  apollo: {
    event: {
      query: require('@/gql/getEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug
        }
      }
    },
    project: {
      query: require('@/gql/getProject').default,
      variables () {
        return {
          random_key: this.$route.params.projectRandomKey
        }
      }
    }
  }
}
</script>
